const infoSitio = {
  // descripcion:
  //   "Tienda de EJEMPLO de productos truper a Mayoreo, con envíos a todo México.",
  // negocio: "Mi Tienda",
  // direccion: ["Oaxaca", "México"],
  // mail: "ventas@cabbur.me",
  // telefono: "971 142 4420",
  // netlifySiteId: "c48fed25-7912-437d-b281-a0602916b685",
  netlifySiteId: process.env.GATSBY_NETLIFY_SITE_ID,
}

export default infoSitio
